<template>
  <AppLayout
    fluid
    :enable-footer="false"
    :enable-background="false"
  >
    <ApplyToTeam />
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/AlternateAppLayout";
import ApplyToTeam from "@/components/Register/ForTeam";

export default {
  name: "ApplyToTeamPage",
  components: {
    ApplyToTeam,
    AppLayout
  },
  data() {
    return {};
  }
};
</script>