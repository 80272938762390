<template>
  <VSkeletonLoader
    grid-list-lg
    fluid
    width="620px"
    type="table-heading,card"
    :loading="activity.isOptionsLoading"
  >
    <VRow class="justify-center">
      <VCol cols="auto">
        <VCard 
          width="620px"
          class="form-card"
        >
          <VCardText>
            <h1 class="heading">
              Great! Let’s Get to Work.
            </h1>
            <h2 class="sub-heading py-2">
              Tell us about you and your company so we can recommend the best products and content.
            </h2>
            <VForm class="mt-2">
              <h3 class="auth-label py-1">
                Your Role
              </h3>
              <Autocomplete
                v-model="form.profile.organization_creator_role_id"
                hide-details
                placeholder="Select Role"
                :searchable="false"
                :items="options.organizationCreatorRole"
              />
              <h3 class="auth-label py-1">
                Company / Organization Size
              </h3>
              <Autocomplete
                v-model="form.profile.organization_size_id"
                hide-details
                placeholder="Select Size"
                :searchable="false"
                :items="options.organizationSize"
              />
              <h3 class="auth-label py-1">
                Program Type(s)
              </h3>
              <VCheckbox
                v-for="(programType, index) in options.programTypes"
                v-model="form.program_type_ids"
                hide-details
                type="checkbox"
                class="mt-1 mb-4"
                :key="index"
                :value="programType.id"
              >
                <template #label>
                  <span class="pl-2 black--text text-body-2">
                    {{ programType.title }}
                  </span>
                </template>
              </VCheckbox>
              <h3 class="auth-label py-1">
                Your Goal
              </h3>
              <p class="text-body-1 mb-1">
                How would you best describe your organization’s primary, near-term goal?
              </p>
              <Autocomplete
                v-model="form.profile.organization_goal_id"
                hide-details
                placeholder="Select Goal"
                :searchable="false"
                :items="options.organizationGoals"
              />
              <h3 class="auth-label py-1">
                When do you plan to implement a new program management solution?
              </h3>
              <Autocomplete 
                v-model="form.profile.program_implementation_timeline_id"
                hide-details
                placeholder="Select Timeline"
                :searchable="false"
                :items="options.programImplementationTimeline"
              />
              <h3 class="auth-label py-1">
                How’d you hear about WeThrive?
              </h3>
              <Autocomplete
                v-model="form.profile.how_did_you_hear_about_us_id"
                placeholder="Select Source"
                :searchable="false"
                :items="options.howDidYouHearAboutUs"
              />
              <VBtn
                block
                color="primary"
                height="54px"
                class="black--text"
                style="border-radius: 9px; box-shadow: 0px 3px 6px 0px #00000033 !important;"
                :disabled="$v.$invalid || $v.$error"
                :loading="form.isSaving"
                @click="updateProfile"
              >
                <span style="font-size: 20px; font-weight: 600;">
                  Save & Continue
                </span>
              </VBtn>
            </VForm>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </VSkeletonLoader>
</template>

<script>
import { mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import FormMixin from "@/mixins/Form";

export default {
  name: "OrgDetailsForm",
  mixins: [FormMixin],
  data() {
    return {
      options: {
        organizationCreatorRole: [],
        organizationSize: [],
        organizationGoals: [],
        programImplementationTimeline: [],
        howDidYouHearAboutUs: [],
      },
      form: {
        profile : {
          organization_creator_role_id: 87,
          organization_size_id: 103,
          organization_goal_id: 77,
          program_implementation_timeline_id: 71,
          how_did_you_hear_about_us_id: 64,
        },
        program_type_ids: [],
      },
    };
  },
  validations: {
    form: {
      profile: {
        organization_creator_role_id: { required },
        organization_size_id: { required },
        organization_goal_id: { required },
        program_implementation_timeline_id: { required },
        how_did_you_hear_about_us_id: { required },
      },
      program_type_ids: { required },
    },
  },
  created() {
    this.activity.isOptionsLoading = true;
  },
  methods: {
    ...mapActions({
      doSaveTeam: "team/save",
      doSetMe: "user/setMe",
      doGetTeamProfileConfig:"register/teamProfileConfig"
    }),
    onGetFormConfig() {
      return this.doGetTeamProfileConfig().then((result) => result.data);
    },
    updateProfile() {
      const loggedInUser = this.$auth.user();
      this.form.id = loggedInUser.team.id;
      this.saveFormModel(this.doSaveTeam, null, this.form, null, false)
        .then((response) => {
          if(response) {
            const user = {
              ...loggedInUser,
              team: response,
            };
            this.doSetMe(user);
            this.$auth.user(user);
            this.$nextTick(() => {
              this.$router.push({
                name: 'program.index',
                params: {
                  team: loggedInUser.team.slug,
                  welcome: true,
                },
              });
            });
          }
          return response;
        })
        .catch((error) => {
          this.$bus.$emit("notificationError", this.parseErrors(error.message), true);
        });
    },
  },
};
</script>