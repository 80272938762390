var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.selectTeam)?_c('VNavigationDrawer',{staticClass:"container pt-0",style:({
      left: _vm.$vuetify.breakpoint.smAndDown ? '0' : ''
    }),attrs:{"floating":"","id":"select-team","height":"min-content","elevation":"0","width":_vm.$vuetify.breakpoint.mdAndUp ? '669px' : '100%',"value":true,"disable-resize-watcher":false,"temporary":true,"fixed":true}},[_c('VRow',{staticStyle:{"height":"56px"},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('close')}}},[_c('VCol',{style:({
          paddingLeft: _vm.$vuetify.breakpoint.mdAndUp ? '0px' : '66px'
        })},[_c('TeamMenuActivator',{attrs:{"is-open":""},on:{"input":function($event){return _vm.$emit('close')}}})],1)],1),_c('VRow',{attrs:{"column":_vm.$vuetify.breakpoint.smAndDown}},[_c('VCol',{class:{
          'pl-0': _vm.$vuetify.breakpoint.mdAndUp
        },attrs:{"xs":"12","md":"5","order":_vm.$vuetify.breakpoint.smAndDown ? 2 : 1}},[(_vm.$user.teams && _vm.$user.teams.length)?_c('VList',{style:({
            maxHeight: 'calc(100vh - 76px)',
            overflowY: 'auto'
          }),attrs:{"two-line":"","dense":"","elevation":"6","color":"white"}},_vm._l((_vm.$user.teams),function(team,key){return _c('VListItem',{key:key,class:{
              'primary--text': _vm.$team.id === team.id
            },on:{"click":function($event){$event.stopPropagation();return _vm.onSelectTeam(team.id)}}},[_c('VListItemContent',[_c('span',[_c('img',{attrs:{"src":team.photo_url}})]),_c('VListItemSubtitle',{staticStyle:{"font-size":"0.65rem","text-transform":"uppercase","font-weight":"bold"}},[_vm._v(" "+_vm._s(team.name)+" ")])],1),_c('VListItemIcon',{staticClass:"align-center",staticStyle:{"margin":"20px 0 0"}},[_c('VTooltip',{attrs:{"top":"","center":"","value":!team.pivot || team.pivot && !team.pivot.is_approved},scopedSlots:_vm._u([{key:"activator",fn:function({on}){return [_c('VIcon',_vm._g({attrs:{"small":"","color":_vm.$team.id === team.id ? 'primary' : 'transparent'}},on),[(!team.pivot || team.pivot && !team.pivot.is_approved)?[_vm._v(" help ")]:[_vm._v(" fiber_manual_record ")]],2)]}}],null,true)},[(!team.pivot || team.pivot && !team.pivot.is_approved)?[_vm._v(" You're not approved yet ")]:_vm._e()],2)],1)],1)}),1):_vm._e()],1),_c('VCol',{attrs:{"xs":"12","md":"7","order":_vm.$vuetify.breakpoint.smAndDown ? 1 : 2}},[_c('PendingInvitations',{staticStyle:{"max-height":"calc(100vh - 128px)","overflow-y":"auto"},on:{"accepted":_vm.onHandleAcceptInvitation,"rejected":_vm.onHandleRejectInvitation}})],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }