<template>
  <AppLayout 
    fluid
    :enable-footer="false"
  >
    <VContainer
      id="login-page"
      fill-height
      fluid
    >
      <VRow align-center>
        <VCol
          cols="12"
          offset-md="2"
          md="6"
          lg="4"
        >
          <RecoverForm />
        </VCol>
      </VRow>
    </VContainer>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/AlternateAppLayout";
import RecoverForm from "@/components/Login/Recover";

export default {
  name: "Recover",
  components: {
    RecoverForm,
    AppLayout
  },
  data() {
    return {};
  }
};
</script>
