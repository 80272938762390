<template>
  <VRow class="auth-form">
    <VCol 
      cols="12" 
      class="text-center"
    >
      <img
        height="70px"
        class="mb-2"
        :src="WeThriveLogo"
      >
    </VCol>
    <VCol cols="12">
      <VRow class="justify-center">
        <VCol cols="auto">
          <VCard 
            width="548px"
            class="form-card"
          >
            <VCardText>
              <h1 
                class="heading"
                style="font-size: 36px;"
              >
                Welcome Back!
              </h1>
              <h2 
                class="sub-heading py-2"
                style="font-size: 18px;"
              >
                Log into your WeThrive account to get started.
                <br>
                No account? No worries!
                <router-link
                  class="link"
                  :to="{ name: 'register' }"
                >
                  Create one here
                </router-link>.
              </h2>
              <SmallTeamBanner
                v-if="currentTeam"
                :team="currentTeam"
              />
              <VForm 
                class="mt-2" 
                @submit.prevent="login"
              >
                <TextField
                  v-model="form.email"
                  type="email"
                  placeholder="Email Address"
                  :error="$v.form.email.$error"
                  :hide-details="$v.form.email.email"
                  :error-messages="$v.form.email.email ? [] : ['Invalid email']"
                  @blur="$v.$touch()"
                  @input="$v.$touch()"
                  @keyup.enter="login"
                />
                <PasswordInput
                  v-model="form.password"
                  hide-details
                  label=""
                  placeholder="Password"
                  :editable="false"
                  :error="$v.form.password.$error"
                  @blur="$v.$touch()"
                  @input="$v.$touch()"
                  @keyup.enter="login"
                />
                <VCheckbox
                  v-model="rememberMe"
                  type="checkbox"
                  hide-details
                  class="mt-1 mb-4"
                >
                  <template #label>
                    <span style="font-size: 12px; color: #898989;">
                      Remember me
                    </span>
                  </template>
                </VCheckbox>
                <VBtn
                  block
                  color="primary"
                  height="54px"
                  class="black--text"
                  style="border-radius: 9px; box-shadow: 0px 3px 6px 0px #00000033 !important;"
                  :loading="activity.isLoading"
                  :disabled="$v.$invalid || $v.$error"
                  @click.prevent="login"
                >
                  <span style="font-size: 20px; font-weight: 600;">
                    Log In
                  </span>
                </VBtn>
                <div 
                  class="text-center mt-5"
                  style="color: #808080;"
                >
                  <router-link 
                    class="link" 
                    :to="{ name: 'recover' }"
                  >
                    Forgot password
                  </router-link>
                </div>
              </VForm>
            </VCardText>
          </VCard>
        </VCol>
      </VRow>
    </VCol>
  </VRow>
</template>

<script>
import { mapActions } from "vuex";
import WeThriveLogo from "@/images/logo_wethrivehub.png";
import { email, required, minLength } from "vuelidate/lib/validators";
import PasswordInput from "@/components/Elements/Forms/PasswordInput";
import FormMixin from "@/mixins/Form";
import NeedsTeamsMixin from "@/mixins/NeedsTeams";

export default {
  name: "LoginForm",
  mixins: [NeedsTeamsMixin, FormMixin],
  components: { PasswordInput },
  data() {
    return {
      WeThriveLogo,
      form: {
        password: "",
        email: "",
      },
    };
  },
  validations: {
    form: {
      password: {
        required,
      },
      email: {
        email,
        required,
      },
    },
  },
  computed: {
    username: {
      get() {
        if (localStorage.email) {
          return localStorage.email;
        } else {
          return this.form.email;
        }
      },
      set(value) {
        this.form.email = value;
      },
    },
    rememberMe: {
      get() {
        return this.$store.state.auth.rememberMe;
      },
      set(value) {
        this.doRememberMe(
          {
            value,
            email: this.form.email,
          },
          { root: true }
        );
      },
    },
  },
  created() {
    this.doLogout();
  },
  methods: {
    ...mapActions({
      doLogin: "auth/login",
      doLogout: "auth/logout",
      doRememberMe: "auth/rememberMe",
    }),
    login() {
      if(this.$v.$invalid || this.$v.$error) {
        return false;
      }
      this.activity.isLoading = true;
      const { username, password = this.form.password } = this;
      this.doLogin({
        username: username.toLowerCase().trim(),
        password,
        rememberMe: this.rememberMe,
      })
        .then(() => {
          this.$log.debug("LoginForm.login: Logged in...waiting for user");

          /**
           * Get the user and log them in
           * TODO: Need to add custom logic here to determine if the user is properly setup
           * Possibly need a flag on the user account or a check on the org info
           */
          // Need to set the current team for the user using API
          // this.$team = this.currentTeam ? this.currentTeam : this.$team;
          // const team = this.$team ? this.$team.slug : this.teamSlug;
          const ready = function() {
            this.$log.debug("LoginForm.login: User is available", this.$team);
            // Will return to using this if problems return with being logged into previous team
            // const previousUrl = (
            //   this.$auth.redirect() || {
            //     from: { fullPath: null },
            //     to: { fullPath: null }
            //   }
            // ).from.fullPath;
            // window.location.href =
            //   previousUrl ||
            //   `${this.teamSlug ? "/" + this.teamSlug : ""}/app/dashboard`;
            const redirect = this.$auth.redirect();
            this.$router.push(
              redirect instanceof Object
                ? redirect.from
                : {
                    name: "dashboard",
                    params: {
                      team: this.$team.slug,
                    },
                  }
            );
          };

          setTimeout(() => {
            if (!this.$auth.ready()) {
              this.$auth.ready(ready);
            } else {
              ready.call(this);
            }
          }, 500);
        })
        .catch((error) => {
          console.log("Error", error);
          if (error.code == 403) {
            window.location.reload();
          } else {
            const response = error ? error.response : null;
            this.$log.debug("Error information", response, error);
            this.error = response
              ? error.response.data.message
              : "Error logging you in";
            this.$bus.$emit("notificationError", this.error, true);
            this.activity.isLoading = false;
            this.doLogout();
          }
        });
    },
  },
};
</script>
