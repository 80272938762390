<template>
  <TextField
    v-if="visible"
    autocomplete="off"
    :error="error"
    :type="type"
    :value="value"
    :name="name"
    :label="label"
    :hide-details="hideDetails"
    :placeholder="placeholder"
    :disabled="isInputDisabled"
    :error-messages="errorMessages"
    :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
    @input="(changedValue) => $emit('input', changedValue)"
    @click:append="onToggleShow"
    v-on="$listeners"
  >
    <VBtn
      slot="append-outer"
      text
      icon
      v-if="editable"
      @click="onToggleEdit"
    >
      <VIcon>{{ isEditing ? 'check' : 'edit' }}</VIcon>
    </VBtn>
  </TextField>
</template>
<script>
import uuid from "uuid";

export default {
  props: {
    label: {
      type: String,
      default: "Password",
    },
    value: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: uuid.v4(),
    },
    placeholder: {
      type: String,
      default: "Enter password",
    },
    error: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.isInputDisabled = this.disabled;
    this.enableEdit = this.editable;
  },
  watch: {
    disabled: function (value) {
      this.isInputDisabled = value === true;
    },
    editable: function (value) {
      this.enableEdit = value === true;
    },
  },
  data() {
    return {
      type: "password",
      showPassword: false,
      enableEdit: false,
      isEditing: false,
      isInputDisabled: false,
    };
  },
  methods: {
    onToggleShow() {
      this.showPassword = !this.showPassword;
      this.type = this.showPassword ? "text" : "password";
    },
    onToggleEdit() {
      if (this.enableEdit) {
        this.isEditing = !this.isEditing;
        this.isInputDisabled = !this.isEditing;
        if (!this.isEditing) {
          this.showPassword = false;
          this.type = "password";
        }
      }
    },
  },
};
</script>