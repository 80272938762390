<template>
  <VApp
    class="wethrive"
    :style="style"
  >
    <Notifications
      v-if="hasMessagesOrErrors"
      :alt-view="altView"
      :messages="messages"
      :errors="errors"
      :timeout="notificationTimeout"
    />
    <VRow
      row
      wrap
      justify-flex-end
      fill-height
      :px-3="$vuetify.breakpoint.mdAndUp && !fluid"
      :px-1="$vuetify.breakpoint.smAndDown && !fluid"
      :py-2="!fluid"
    >
      <VCol
        class="d-flex align-center py-0"
        :xs="$vuetify.breakpoint.smAndDown || fluid ? 12 : false"
      >
        <slot />
      </VCol>
      <VCol
        v-if="enableFooter"
        cols="12"
        class="d-flex align-end justify-center footer-section"
      >
        <div class="pb-4">
          <VBtn
            small
            text
            href="https://wethrive.tech/"
            target="_blank'"
          >
            <span style="font-size: 14px; color: #808080;">
              About WeThrive
            </span>
          </VBtn>
          <span style="color: #808080;"> • </span>
          <VBtn
            small
            text
            :to="{ name: 'terms-and-conditions' }"
          >
            <span style="font-size: 14px; color: #808080;">
              Terms and Conditions
            </span>
          </VBtn>
          <span style="color: #808080;"> • </span>
          <VBtn
            small
            text
            :to="{ name: 'privacy-policy' }"
          >
            <span style="font-size: 14px; color: #808080;">
              Privacy Policy
            </span>
          </VBtn>
        </div>
      </VCol>
    </VRow>
  </VApp>
</template>

<script>
import NotificationMixin from "@/mixins/Notification";
// import BackgroundImage from "@/images/illustration.jpg";
import BackgroundImage from "@/images/pattern.png";

export default {
  name: "AlternateAppLayout",
  mixins: [NotificationMixin],
  props: {
    enableBackground: {
      type: Boolean,
      default: true,
    },
    fluid: {
      type: Boolean,
      default: true,
    },
    enableFooter: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    style() {
      return {
        backgroundImage: this.enableBackground
          ? `url('${BackgroundImage}')`
          : "",
        /* backgroundPosition: "bottom right",
        backgroundSize: "auto 100%",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed", */
        backgroundSize: "100% 100%",
        paddingBottom: "0px",
        overflow: "hidden auto",
        maxHeight: "100vh",
      };
    },
  },
  methods: {
    initIntercom() {
      this.$log.debug("[App]: Intercom: booting");
      this.$intercom.boot({
        userId: this.$user.id,
        user_id: this.$user.id,
        name: this.$user.name,
        email: this.$user.email,
        role: this.$user.role_name,
        user_hash: this.$user.hash,
        company_id: this.$user.current_team_id,
        company_website: this.$team.website,
      });
      this.$intercom.hide();
      this.$log.debug("[App]: Intercom: booted");
    },

    onToggleIntercom() {
      if (!this.$intercom.visible) {
        this.$intercom.show();
      } else {
        this.$intercom.hide();
      }
    },
  },
  created() {
    this.$bus.$on("toggleIntercom", () => {
      this.onToggleIntercom();
    });
  },
};
</script>