import BaseApi from "./_base-api";

/**
 * Program API
 *
 * @class Api
 * @extends {BaseApi}
 */
class Api extends BaseApi {
	register(user) {
		return this.api()
			.post(this.getUrl("signup", "signup", null, "api"), user)
			.catch(error => {
				this.log().error("[Services: Register: Register]:", error);
				throw this.utils.resolveError(error);
			});
	}
	/**
	 * Register team with owner details
	 *
	 * @param {*} params
	 * @returns Promise
	 * @memberof Api
	 */
	registerTeam(params) {
		return this.api()
			.post("api/register/team", params)
			.catch(error => {
				this.log().error("[Services: Register: Register Team]:", error);
				throw this.utils.resolveError(error);
			});
	}
	teamProfileConfig() {
		return this.api()
			.get("api/team-profile-details")
			.catch(error => {
				this.log().error("[Services: Register: Team Profile Config]:", error);
				throw this.utils.resolveError(error);
			});
	}
}

const api = new Api({
	type: "Register",
	basePath: "api",
	path: ""
});

export default api;
