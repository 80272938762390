<template>
  <VRow class="auth-form">
    <VCol 
      cols="12" 
      class="text-center"
    >
      <img
        height="70px"
        :src="WeThriveLogo"
      >
    </VCol>
    <VCol 
      cols="12"
      class="d-flex justify-center"
    >
      <VTabs
        v-model="activeTab"
        class="d-none"
      >
        <VTab>Create Team</VTab>
        <VTab>Team Details</VTab>
        <VTab>Invite Team</VTab>
      </VTabs>
      <VTabsItems 
        v-model="activeTab"
        touchless 
        :class="{
          'pa-8 rounded-lg': activeTab == 2,
        }"
        :style="{
          backgroundColor: activeTab == 2 ? '#ffffff' : 'transparent',
        }"
      >
        <VTabItem>
          <RegisterOrganizationForm @changeTab="(tab) => activeTab = tab" />
        </VTabItem>
        <VTabItem>
          <OrganizationDetailsForm />
        </VTabItem>
        <VTabItem>
          <VAlert
            v-if="error"
            dismissible
            :value="true"
          >
            {{ error }}
          </VAlert>
          <template v-if="activity.verifyingInvite">
            <h2>Verifying Invitation</h2>
            <VProgressLinear
              indeterminate
              color="primary"
              class="mb-0"
            />
          </template>
          <RegisterMemberForm 
            v-else 
            :invite="invite" 
          />
        </VTabItem>
      </VTabsItems>
    </VCol>
    <VCol 
      v-if="activeTab == 2 && !activity.verifyingInvite"
      cols="12"
      class="d-flex justify-center"
    >
      <VCard 
        height="52px"
        class="mt-2 rounded-lg"
        id="register-form-footer"
        :width="error ? '636px' : '468px'"
      >
        <VCardText class="d-flex align-center">
          <router-link
            to="/"
            class="px-3"
          >
            Already Registered? Log In.
          </router-link>
          <VSpacer />
          <span>Powered by&nbsp;</span>
          <span>
            <img
              style="height: 26px;"
              :src="WeThriveLogo"
            >
          </span>
        </VCardText>
      </VCard>
    </VCol>
  </VRow>
</template>

<script>
import { mapActions } from "vuex";
import WeThriveLogo from "@/images/logo_wethrivehub.png";
import FormMixin from "@/mixins/Form";
import NeedsTeamsMixin from "@/mixins/NeedsTeams";
import RegisterMemberForm from "./MemberForm";
import RegisterOrganizationForm from "./OrganizationForm";
import OrganizationDetailsForm from "./OrganizationDetailsForm";

export default {
  name: "RegisterForm",
  components: {
    RegisterMemberForm,
    RegisterOrganizationForm,
    OrganizationDetailsForm,
  },
  mixins: [NeedsTeamsMixin, FormMixin],
  data() {
    return {
      WeThriveLogo,
      activeTab: 0,
      invite: null,
      error: null,
      activity: {
        verifyingInvite: false,
      },
    };
  },
  created() {
    if(this.$route.query.invitation) {
      this.activeTab = 2;
      this.activity.verifyingInvite = true;
      this.getInvitation(this.$route.query.invitation)
        .then((invite) => {
          if (invite instanceof Object && invite.id) {
            this.invite = invite;
          } else if (invite == "No records") {
            this.error = "Your invitation is not valid. You may contact the team owner to ask for another invite";
          }
          this.activity.verifyingInvite = false;
        })
        .catch((error) => {
          this.error = "Your invitation is expired. You may contact the team owner to ask for another invite";
          this.$log.warn("Invite not found");
          this.activity.verifyingInvite = false;
        });
    } else {
      this.activeTab = 0;
      const loggedInUser = this.$auth.user();
      if(loggedInUser.hasOwnProperty("team")) {
        if(loggedInUser.role_name != "Owner" || loggedInUser.team.is_profile_complete) {
          this.$router.push({
            name: "program.index",
            params: {
              team: this.$team.slug,
            },
          });
        } else {
          this.activeTab = 1;
        }
      }
    }
  },
  methods: {
    ...mapActions({
      getInvitation: "team/verifyInvite",
    }),
  },
};
</script>
<style lang="scss">
#register-form-footer {
  font-size: 0.8rem;
  a {
    height: 12px;
    color: $font-light-color;
    font-size: 12px;
    font-weight: 500;
    line-height: 14px;
  }
}
</style>