<template>
  <AppLayout>
    <VContainer
      fluid
      id="login-page"
    >
      <VRow 
        align-center
        justify-center
        class="overflow-x-hidden overflow-y-auto"
      >
        <VCol
          lg="6"
          md="8"
          sm="12"
        >
          <LoginForm />
        </VCol>
      </VRow>
    </VContainer>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/AlternateAppLayout";
import LoginForm from "@/components/Login";

export default {
  name: "Login",
  components: {
    LoginForm,
    AppLayout
  },
  data() {
    return {};
  }
};
</script>